import domready from 'domready'

document.addEventListener('scroll', (e) => {
  const docClassList = document.documentElement.classList
  if(!docClassList.contains('scrolled')){
    docClassList.add('scrolled')
  }
})
document.addEventListener('click', (e) => {
  const docClassList = document.documentElement.classList
  if(!docClassList.contains('clicked')){
    docClassList.add('clicked')
  }
})
domready(() => {
  const callback = function(mutationsList, observer) {
    for(const mutation of mutationsList) {
      if (mutation.target.matches('.clicked,.scrolled')){
        document.querySelectorAll('.lazyload-on-interact').forEach((lazyElm) => {
          const lazyElmClassList = lazyElm.classList
          if(!lazyElmClassList.contains('lazyload') && !lazyElmClassList.contains('lazyloaded')){
            lazyElmClassList.add('lazyload')
          }
        })
        document.querySelectorAll('.eagerload-on-interact[data-src]').forEach((eagerElm) => {
          if(!eagerElm.classList.contains('lazyloaded')){
            eagerElm.classList.add('lazyloaded')
            eagerElm.setAttribute('src', eagerElm.getAttribute('data-src'))
          }
        })
      }
    }
  };
  const config = { attributes: true, childList: false, subtree: false };
  const observer = new MutationObserver(callback);
  observer.observe(document.documentElement, config);
});
